'use strict';


angular.module('kljDigitalLibraryApp')

.controller('NotificationCtrl', ["$rootScope", "$scope", "commonVariables", "appConfig", "NotificationsService",
    function($rootScope, $scope, commonVariables, appConfig, NotificationsService) {

        var init = function() {
            commonVariables.isHome = false;
            $rootScope.title = 'Notifications';

            getNotifications();
        }

        var getNotifications = function() {
            NotificationsService.getList({ "pageNumber": $scope.notifyPNo, "pageCount": 10 })
                .then(function(response) {
                    $scope.notifyPNo = $scope.notifyPNo + 1;
                    $scope.notifications = $scope.notifications.concat(response);
                })
                .catch(function(error) {
                    console.log("error from service-->", error);
                });
        }

        init();

    }
]);